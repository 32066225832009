import { useEffect, useRef, useState, ReactNode } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { FaTimes } from "react-icons/fa";
import { ServiceCard } from "src/components";
import Collapse from "@kunukn/react-collapse";

interface ServiceSentencesInterface {
  service: string;
  sentences: string[];
}

const serviceInfo: ServiceSentencesInterface[] = [
  {
    service: "Email Marketting",
    sentences: [
      "Boost customer engagement with targeted email marketing campaigns.",
      "Enhance brand loyalty using personalized, data-driven strategies.",
      "Optimize deliverability rates for maximum inbox visibility.",
      "Elevate click-through rates with compelling call-to-actions.",
      "Improve open rates with attention-grabbing subject lines.",
      "Utilize segmentation for tailored content and promotions.",
      "Maximize ROI with data-driven email marketing automation.",
      "Increase customer retention through timely, relevant messaging.",
      "Harness A/B testing for continuous campaign optimization.",
      "Benefit from expert insights to elevate email performance.",
    ],
  },
  {
    service: "Web Design & Web Development",
    sentences: [
      "Craft responsive, user-centric web designs for seamless experiences.",
      "Build custom, high-performance websites tailored to your brand.",
      "Elevate user experience with intuitive navigation and layout.",
      "Optimize website speed and performance for improved user engagement.",
      "Create mobile-first designs for seamless cross-device browsing.",
      "Implement SEO best practices to maximize organic search visibility.",
      "Employ cutting-edge technologies for innovative web development.",
      "Ensure website accessibility for inclusive, barrier-free experiences.",
      "Deliver scalable, maintainable solutions for long-term success.",
      "Collaborate with clients to transform their visions into reality.",
    ],
  },
  {
    service: "App Development",
    sentences: [
      "Develop high-quality, feature-rich mobile apps for iOS and Android.",
      "Leverage best practices for seamless user experience and performance.",
      "Create scalable, robust applications to accommodate future growth.",
      "Implement engaging UI/UX designs for exceptional app usability.",
      "Utilize agile methodologies for efficient app development processes.",
      "Ensure app security and data privacy with robust encryption.",
      "Integrate third-party APIs for enhanced functionality and features.",
      "Optimize app performance with continuous monitoring and updates.",
      "Provide cross-platform app solutions for cost-effective development.",
      "Offer comprehensive app maintenance and support services post-launch.",
    ],
  },
  {
    service: "UI/UX",
    sentences: [
      "Design intuitive, user-centered interfaces for seamless interactions.",
      "Create visually engaging, responsive designs for all devices.",
      "Implement modern UI trends for a cutting-edge user experience.",
      "Conduct user research and testing to refine design solutions.",
      "Optimize user flows for improved usability and conversion.",
      "Develop accessible, inclusive designs for a diverse audience.",
      "Incorporate brand identity into a cohesive, memorable UX.",
      "Utilize data-driven design to enhance user satisfaction.",
      "Iterate and refine designs through ongoing collaboration.",
      "Align UI/UX with business goals for maximum impact.",
    ],
  },
  {
    service: "Web Analytics",
    sentences: [
      "Track user behavior and engagement with advanced analytics.",
      "Uncover actionable insights for data-driven decision-making.",
      "Optimize website performance through in-depth metric analysis.",
      "Identify growth opportunities with comprehensive traffic evaluation.",
      "Monitor and improve SEO efforts with keyword tracking.",
      "Analyze user demographics for targeted marketing strategies.",
      "Leverage A/B testing to enhance conversion rates.",
      "Evaluate campaign success with goal tracking and reporting.",
      "Monitor website health with real-time error reporting.",
      "Utilize custom dashboards for tailored analytics and reporting.",
    ],
  },
  {
    service: "SEO Optimization",
    sentences: [
      "Boost organic search rankings with comprehensive keyword research.",
      "Optimize on-page elements for enhanced search engine visibility.",
      "Develop high-quality, shareable content to attract inbound links.",
      "Improve website architecture for seamless user experience and crawling.",
      "Utilize technical SEO strategies for optimal indexing and ranking.",
      "Implement schema markup for rich snippets and SERP enhancements.",
      "Monitor and analyze SEO performance for ongoing optimization.",
      "Enhance local search presence with targeted geo-specific strategies.",
      "Employ white-hat techniques to ensure long-term SEO success.",
      "Collaborate with clients to align SEO efforts with business objectives.",
    ],
  },
  {
    service: "Content Creation",
    sentences: [
      "Craft engaging, well-researched content to captivate your audience.",
      "Develop tailored content strategies aligned with business goals.",
      "Produce SEO-optimized content for improved search rankings.",
      "Deliver diverse content formats, including blogs, articles, and videos.",
      "Utilize storytelling techniques to connect with your target market.",
      "Implement content audits to identify areas for improvement.",
      "Optimize content distribution channels for maximum reach and impact.",
      "Maintain consistent brand voice and messaging across all content.",
      "Measure content performance to inform data-driven adjustments.",
      "Collaborate with clients to create compelling, industry-specific content.",
    ],
  },
  {
    service: "Social Media Management",
    sentences: [
      "Develop tailored social media strategies to enhance brand presence.",
      "Create engaging, shareable content for diverse social platforms.",
      "Optimize posting frequency and timing for maximum reach.",
      "Monitor and respond to audience feedback and inquiries.",
      "Leverage platform-specific tools for targeted advertising campaigns.",
      "Implement hashtag strategies to expand content discoverability.",
      "Utilize analytics for data-driven social media optimization.",
      "Foster community growth and brand loyalty through authentic engagement.",
      "Manage social media crises with prompt, professional communication.",
      "Collaborate with influencers to amplify your brand's reach.",
    ],
  },
  {
    service: "Social Media Stratergy",
    sentences: [
      "Define clear objectives aligned with your overall business goals.",
      "Identify target audience segments for precise, effective messaging",
      "Select the most suitable social platforms for your brand's presence.",
      "Develop a consistent, authentic brand voice and visual identity.",
      "Create a content calendar to streamline posting and engagement.",
      "Leverage data-driven insights to refine and optimize strategies.",
      "Monitor industry trends and competitor activity for informed decisions.",
      "Employ paid advertising for precise audience targeting and reach.",
      "Set measurable KPIs to track the success of your social strategy.",
      "Continuously adapt and evolve your approach to stay ahead of the curve.",
    ],
  },
  {
    service: "Social Media Analytics",
    sentences: [
      "Measure and analyze key performance metrics across social platforms.",
      "Gain actionable insights to inform data-driven strategy adjustments.",
      "Monitor audience engagement and sentiment for informed content creation.",
      "Identify top-performing content to refine future posts and campaigns.",
      "Assess the effectiveness of paid advertising for ROI optimization.",
      "Utilize platform-specific analytics tools for comprehensive data analysis.",
      "Track follower growth to gauge brand visibility and community expansion.",
      "Analyze competitor performance for benchmarking and strategy refinement.",
      "Evaluate referral traffic from social media to your website.",
      "Leverage custom dashboards for tailored, in-depth analytics reporting.",
    ],
  },
  {
    service: "Social Media Engagement",
    sentences: [
      "Create compelling, shareable content to spark audience interaction.",
      "Respond promptly to comments, messages, and mentions.",
      "Encourage user-generated content to foster community involvement.",
      "Leverage polls, surveys, and quizzes to increase engagement.",
      "Utilize visual content, like images and videos, for added appeal.",
      "Host contests, giveaways, and challenges to incentivize participation.",
      "Highlight and celebrate user success stories and testimonials.",
      "Collaborate with influencers and industry experts for increased credibility.",
      "Engage with your audience during live events and webinars.",
      "Monitor engagement metrics to continuously optimize your approach.",
    ],
  },
  {
    service: "Community Management",
    sentences: [
      "Build and nurture a loyal, engaged online community for your brand.",
      "Foster authentic relationships through meaningful conversations and interactions.",
      "Monitor and moderate discussions to ensure a positive, inclusive environment.",
      "Address and resolve community concerns with empathy and professionalism.",
      "Create exclusive content and experiences for community members.",
      "Collaborate with community members for feedback and ideas.",
      "Leverage community insights to inform business decisions and strategy.",
      "Recognize and reward active, valuable members to encourage participation.",
      "Establish and enforce community guidelines for a respectful atmosphere.",
      "Facilitate networking and connection-building among community members.",
    ],
  },
];

const Services = () => {
  const scrollRef = useRef<any>(null);
  const serviceMoreInfoRef = useRef<any>(null);
  const whatWeDoRef = useRef<any>(null);
  const [collapseOpen, setCollapseOpen] = useState<boolean>(false);
  const [serviceTitle, setCollapseTitle] = useState<string>("");
  const [serviceBrief, setServiceBrief] = useState<ReactNode>("");
  const [serviceSentences, setServiceSentences] = useState<string[] | undefined>([]);

  const handleScroll = (direction: string) => {
    if (direction === "left") {
      scrollRef?.current.scrollBy({ left: -300, behavior: "smooth" });
    } else {
      scrollRef?.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  const scrollLeft = () => {
    scrollRef.current?.scrollBy({ left: 300, behavior: "smooth" });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      scrollLeft();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const collapseHandler = (title: string, brief: ReactNode) => {
    const elementPosition = serviceMoreInfoRef.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - 100;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    setServiceSentences(
      serviceInfo.find((serviceInfo) => serviceInfo.service === title)?.sentences
    );
    setCollapseOpen(true);
    setCollapseTitle(title);
    setServiceBrief(brief);
  };

  return (
    <>
      <div id="what-we-do" className="service-wrapper" ref={whatWeDoRef}>
        <h1>What We Do</h1>
        <div className="service-slider-wrapper">
          <button onClick={() => handleScroll("left")} className="left-btn">
            <MdKeyboardArrowLeft size={30} />
          </button>
          <div className="services-container" ref={scrollRef}>
            <ServiceCard title="Email Marketting" handler={collapseHandler}>
              Boost customer engagement, drive sales, and nurture leads with our tailored email
              marketing campaigns, leveraging segmentation, automation, and analytics.
            </ServiceCard>

            <ServiceCard title="Web Design & Web Development" handler={collapseHandler}>
              Craft visually stunning, user-friendly websites with our expert web design and
              development services, ensuring seamless performance and functionality.
            </ServiceCard>

            <ServiceCard title="App Development" handler={collapseHandler}>
              Deliver captivating mobile experiences with our iOS and Android app development
              services, merging exceptional design, functionality, and platform compatibility.
            </ServiceCard>

            <ServiceCard title="UI/UX" handler={collapseHandler}>
              Elevate user satisfaction with our cutting-edge UI/UX design services, focusing on
              usability, accessibility, and engaging interactions for digital products.
            </ServiceCard>

            <ServiceCard title="Web Analytics" handler={collapseHandler}>
              Optimize website performance with our comprehensive web analytics services, utilizing
              data-driven insights to drive growth, engagement, and conversions.
            </ServiceCard>

            <ServiceCard title="SEO Optimization" handler={collapseHandler}>
              We improve website visibility in search engines by optimizing content, keywords, and
              other elements to increase organic traffic.
            </ServiceCard>

            <ServiceCard title="Content Creation" handler={collapseHandler}>
              We produce high-quality content that engages and informs the target audience, driving
              traffic and promoting brand recognition.
            </ServiceCard>

            <ServiceCard title="Social Media Management" handler={collapseHandler}>
              We manage social media accounts, create and curate content, and analyze data to build
              brand awareness and increase engagement.
            </ServiceCard>

            <ServiceCard title="Social Media Stratergy" handler={collapseHandler}>
              We develop a tailored social media plan to reach target audiences, increase
              engagement, and build brand awareness through content and analysis.
            </ServiceCard>

            <ServiceCard title="Social Media Analytics" handler={collapseHandler}>
              We use data to measure social media performance, track audience behavior, and optimize
              strategies for increased engagement and ROI.
            </ServiceCard>

            <ServiceCard title="Social Media Engagement" handler={collapseHandler}>
              We facilitate communication between brands and their audience, building relationships
              and increasing brand loyalty through social media interaction.
            </ServiceCard>

            <ServiceCard title="Community Management" handler={collapseHandler}>
              We foster positive relationships between brands and their community, moderating
              interactions and building engagement to promote loyalty and growth.
            </ServiceCard>
          </div>

          <button onClick={() => handleScroll("right")} className="right-btn">
            <MdKeyboardArrowRight size={30} />
          </button>
        </div>
      </div>
      <Collapse isOpen={collapseOpen}>
        <div className="collapse-content-block" ref={serviceMoreInfoRef}>
          <FaTimes
            onClick={() => {
              setCollapseOpen(false);
              whatWeDoRef.current.scrollIntoView();
            }}
            size={20}
          />
          <h1>{serviceTitle}</h1>
          <p>{serviceBrief}</p>
          <ul>
            {serviceSentences?.map((serviceSentence, i) => (
              <li style={{ color: "#000" }} key={i}>
                {serviceSentence}
              </li>
            ))}
          </ul>
        </div>
      </Collapse>
    </>
  );
};
export default Services;
