import { TypeAnimation } from "react-type-animation";
import { HeroImg } from "src/assets/images";

const Hero = () => {
  return (
    <div id="home" className="hero">
      <img src={HeroImg} alt="hero" />

      <h1>We Are a Team of Experts</h1>
      <p>
        in &nbsp;
        <TypeAnimation
          sequence={[
            "Email Marketing.",
            2000,
            "Web Designing.",
            2000,
            "Web Development.",
            2000,
            "App Development.",
            2000,
            "UI/UX",
            2000,
            "Web Analytics.",
            2000,
            "SEO Optimization",
            2000,
            "Content creation",
            2000,
            "Social Media Management",
            2000,
            "Social Media Strategy",
            2000,
            "Social Media Analytics",
            2000,
            "Social Media Engagement",
            2000,
            "Community Management",
            2000,
          ]}
          wrapper="span"
          cursor={true}
          repeat={Infinity}
        />
      </p>
    </div>
  );
};
export default Hero;
