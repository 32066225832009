import { useState } from "react";
import { LogoImg } from "src/assets/images";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaUser, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [navOpen, setNavOpen] = useState<boolean>(false);

  return (
    <nav className={navOpen ? "open" : ""}>
      <img src={LogoImg} alt="logo" />
      <ul>
        <li>
          <a href="#home">Home</a>
        </li>
        <li>
          <a href="#what-we-do">What we do</a>
        </li>
        <li>
          <a href="#what-we-think">What we think</a>
        </li>
        <li>
          <a href="#who-are-we">Who are we</a>
        </li>
        <li>
          <a href="#our-work">Our work</a>
        </li>
        <li>
          <a href="#get-in-touch">Get in touch</a>
        </li>
      </ul>
      <button className="myspace-btn">
        <FaUser />
        My Space
      </button>
      <button onClick={() => setNavOpen(true)} className="mobile-menu-btn">
        <GiHamburgerMenu size={35} />
      </button>
      <button onClick={() => setNavOpen(false)} className="mobile-menu-btn-close">
        <FaTimes size={35} />
      </button>
    </nav>
  );
};
export default Navbar;
