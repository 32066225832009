import { LogoImg } from "src/assets/images";
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className="mobile-footer">
        <img src={LogoImg} alt="logo" />
        <p className="contact-wrapper">
          No.55 <br /> St.Anthony's Mawatha <br /> Colombo 03 <br />
          <a href="mailto:info@tinkers.biz">info@tinkers.biz</a> <br />{" "}
          <a href="tel:+9477 343 4570">+94 77 343 4570</a>
        </p>
        <div className="social-networks">
          <FaFacebook size={30} />
          <a href="https://www.instagram.com/tinkers.global/">
            <FaInstagram size={30} />
          </a>
          <a href="https://www.instagram.com/tinkers.global/">
            <FaWhatsapp size={30} />
          </a>
          <FaLinkedin size={30} />
        </div>
      </div>
      <div className="desktop-footer">
        <p>
          © <script>document.write(new Date().getFullYear())</script> Made with ❤️ by Tinkers
        </p>
      </div>
    </footer>
  );
};
export default Footer;
