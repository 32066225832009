import {
  Footer,
  GetInTouch,
  Hero,
  Navbar,
  OurWork,
  Services,
  WhatWeThink,
  WhoAreWe,
} from "./pages";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const App = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Services />
      <WhatWeThink />
      <WhoAreWe />
      <OurWork />
      <GetInTouch />
      <WhatsAppWidget
        phoneNo="94762554608"
        position="right"
        widgetWidth="300px"
        widgetWidthMobile="260px"
        messageBox={true}
        messageBoxTxt=""
        iconSize="55"
        iconColor="#fff"
        iconBgColor="#075b51"
        headerIcon="https://i.ibb.co/bWrQ30f/222-removebg-preview-removebg-preview.png"
        headerIconColor="pink"
        headerTxtColor="black"
        headerBgColor="#085E54"
        headerTitle="Support - Tinkers"
        headerCaption="Online"
        bodyBgColor="#E5DDD5"
        chatPersonName="Support"
        chatMessage="Hi, How can I help?"
        footerBgColor="#F0F0F0"
        placeholder=""
        btnBgColor="#4FCF5E"
        btnTxt="Send message"
        btnTxtColor="#fff"
      />
      <Footer />
    </>
  );
};

export default App;
