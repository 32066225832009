import { WhatWeThinkImg } from "src/assets/images";

const WhatWeThink = () => {
  return (
    <div id="what-we-think" className="what-we-think">
      <div className="content">
        <h1>What We Think</h1>
        <p>
          Personalized marketing is becoming increasingly important in the digital marketing
          industry as consumers expect tailored experiences. Gathering and using customer data is
          crucial for success.
        </p>
      </div>
      <div className="image-container">
        <img src={WhatWeThinkImg} alt="what-we-think" />
      </div>
    </div>
  );
};
export default WhatWeThink;
