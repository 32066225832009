import { useRef, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import {
  As1,
  As2,
  As3,
  As4,
  As5,
  As6,
  As7,
  As8,
  As9,
  Mi1,
  Mi2,
  Mi3,
  Mi4,
  Mi5,
  Mi6,
  Ni1,
  Ni10,
  Ni2,
  Ni3,
  Ni4,
  Ni5,
  Ni6,
  Ni7,
  Ni8,
  Ni9,
} from "src/assets/images";
import { PortfolioCard } from "src/components";

interface PortFolioInterface {
  img: any;
}

const graphicDesigns: PortFolioInterface[] = [
  { img: As1 },
  { img: As2 },
  { img: As3 },
  { img: As4 },
  { img: As5 },
  { img: As6 },
  { img: As7 },
  { img: As8 },
  { img: As9 },
  { img: Ni1 },
  { img: Ni2 },
  { img: Ni3 },
  { img: Ni4 },
  { img: Ni5 },
  { img: Ni6 },
  { img: Ni7 },
  { img: Ni8 },
  { img: Ni9 },
  { img: Ni10 },
];

const appDevelopment: PortFolioInterface[] = [
  { img: Mi1 },
  { img: Mi2 },
  { img: Mi3 },
  { img: Mi4 },
  { img: Mi5 },
  { img: Mi6 },
];

const OurWork = () => {
  const scrollRef = useRef<any>(null);
  const [openedImage, setOpenedImage] = useState<any>(null);
  const [activePortfolioSection, setActivePortfolioSection] = useState<string>("Graphic Designs");
  const [activePortfolioArr, setActivePortfolioArr] =
    useState<PortFolioInterface[]>(graphicDesigns);

  const handleScroll = (direction: string) => {
    if (direction === "left") {
      scrollRef?.current.scrollBy({ left: -300, behavior: "smooth" });
    } else {
      scrollRef?.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  const handleImageDisplay = (image: any) => {
    if (openedImage === null) {
      setOpenedImage(image);
    } else {
      setOpenedImage(null);
    }
  };

  const handleActivePortfolio = (title: string) => {
    if (title === "Graphic Designs") {
      setActivePortfolioSection(title);
      setActivePortfolioArr(graphicDesigns);
    } else if (title === "App Development") {
      setActivePortfolioSection(title);
      setActivePortfolioArr(appDevelopment);
    }
  };

  return (
    <div id="our-work" className="service-wrapper  image-slider-wrapper">
      <h1>Our Work</h1>
      <p style={{ width: " min(800px, 100%)", textAlign: "center", lineHeight: "150%" }}>
        Welcome to our team's portfolio, a vibrant display of our collective creativity, innovation,
        and expertise. Dive in and experience the collaborative passion that fuels our success!
      </p>

      <div className="section-wrapper">
        <div
          onClick={() => handleActivePortfolio("Graphic Designs")}
          className={activePortfolioSection === "Graphic Designs" ? "active" : undefined}
        >
          Graphic Designs
        </div>
        <div
          onClick={() => handleActivePortfolio("App Development")}
          className={activePortfolioSection === "App Development" ? "active" : undefined}
        >
          App Development
        </div>
      </div>

      <div className="service-slider-wrapper">
        <button onClick={() => handleScroll("left")} className="left-btn">
          <MdKeyboardArrowLeft size={30} />
        </button>
        <div className="services-container" ref={scrollRef}>
          {activePortfolioArr.map((img) => (
            <PortfolioCard
              key={img.img}
              image={img.img}
              handler={() => handleImageDisplay(img.img)}
              imageIsOpen={openedImage === img.img}
            />
          ))}
        </div>

        <button onClick={() => handleScroll("right")} className="right-btn">
          <MdKeyboardArrowRight size={30} />
        </button>
      </div>
    </div>
  );
};
export default OurWork;
