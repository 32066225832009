import { FaTimes } from "react-icons/fa";

interface PortfolioCardInterface {
  handler: () => void;
  imageIsOpen: boolean;
  image: any;
}

const PortfolioCard = ({ handler, imageIsOpen, image }: PortfolioCardInterface): JSX.Element => {
  return (
    <div className={`service-card card-img ${imageIsOpen && "pop-up"}`}>
      <button>
        <FaTimes onClick={handler} size={30} />
      </button>
      <img onClick={handler} src={image} alt="card-img" />
    </div>
  );
};
export default PortfolioCard;
