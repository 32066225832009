import { useState } from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import emailjs from "@emailjs/browser";

const GetInTouch = () => {
  const [sending, setSending] = useState<boolean>(false);

  const sendEmail = (e: any) => {
    e.preventDefault();
    setSending(true);
    emailjs
      .sendForm("service_shk4r7u", "template_3btk3z6", e.target, "s90vWX-M9dY9xJerf")
      .then(() => {
        alert("Email sent successfully");
        e.target.reset();
      })
      .catch(() => alert("Something went wrong"))
      .finally(() => setSending(false));
  };

  return (
    <div id="get-in-touch">
      <div>
        <h1>Get in touch</h1>
        <p>
          We're eager to connect with you! Contact us for questions, assistance, or to share your
          thoughts. Let's explore collaboration opportunities together.
        </p>
        <div className="contact-social-networks">
          <p className="contact-wrapper">
            No.55 <br /> St.Anthony's Mawatha <br /> Colombo 03 <br />
            <a href="mailto:info@tinkers.biz">info@tinkers.biz</a> <br />{" "}
            <a href="tel:+9477 343 4570">+94 77 343 4570</a>
          </p>
          <div className="social-networks">
            <FaFacebook size={30} />
            <a href="https://www.instagram.com/tinkers.global/">
              <FaInstagram size={30} />
            </a>
            <a href="https://wa.me/94762554608?text=hi">
              <FaWhatsapp size={30} />
            </a>
            <FaLinkedin size={30} />
          </div>
        </div>
      </div>
      <form className="form-wrapper" onSubmit={sendEmail}>
        <input type="text" name="from_name" placeholder="Name" />
        <input type="email" name="from_email" placeholder="Email" />
        <input type="text" name="subject" placeholder="Subject" />
        <textarea rows={5} name="message" placeholder="Message" />
        <button>{sending ? "Sending..." : "Send Message"}</button>
      </form>
    </div>
  );
};
export default GetInTouch;
