import { ReactNode } from "react";

interface ServiceCardInterface {
  title: string;
  children: ReactNode;
  handler: (title: string, brief: ReactNode) => void;
}

const ServiceCard = ({ title, children, handler }: ServiceCardInterface) => {
  return (
    <div className="service-card">
      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <h1>{title}</h1>
        <p>{children}</p>
      </div>
      <button onClick={() => handler(title, children)}>More Info</button>
    </div>
  );
};
export default ServiceCard;
