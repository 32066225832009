const WhoAreWe = () => {
  return (
    <div id="who-are-we" className="who-are-we">
      <h1>Who Are We</h1>
      <p>
        We are a team of digital content creators who produce engaging and informative content for
        online audiences. Our focus is on quality and creativity to help our clients succeed in the
        competitive world of digital marketing.
      </p>
    </div>
  );
};
export default WhoAreWe;
